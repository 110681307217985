import React from 'react'
import Brand from './Brand.js'
import Navigation from './Navigation.js'
import NavToggle from './NavToggle.js'
import Social from './Social.js'
import Footer from './Footer.js'

export default function Sidebar() {
  return (
    <nav id="tmSidebar" className="tm-bg-black-transparent tm-sidebar">
            <NavToggle />
            <div className="tm-sidebar-sticky">
              <Brand />
              <Navigation />
              <Social/>
              <Footer />
            </div>
          </nav>
  )
}
