import React from 'react'

export default function Brand() {
  return (
    <div className="tm-brand-box">
                <div className="tm-double-border-1">
                  <div className="tm-double-border-2">
                    <h1 className="tm-brand text-uppercase">Asad Rahman</h1>
                  </div>
                </div>
              </div>
  )
}
