import Layout from "./Layout";
import Sidebar from "./Sidebar";
import Main from "./Main";
import ParallaxWindow from './ParallaxWindow';
import About from "../sections/About";
import Services from "../sections/Services";
import Work from "../sections/Work";
import Clients from "../sections/Clients";
import Contact from "../sections/Contact";

function App() {

  return (
    <div className="App">
      <Layout>
        <Sidebar/>
        <Main>
          <ParallaxWindow />
          <About />
          <Services />
          <Work />
          <Clients />
          <Contact />
        </Main>
      </Layout>
    </div>
  );
}

export default App;
