import React from 'react'

export default function Introduce({id,title,content1,content2}) {
  return (
        <section id={id} className="tm-section tm_center">
            <div className="tm-bg-white-transparent tm-intro">
                <h2 className="tm-section-title mb-4 text-uppercase tm-color-primary">{title}</h2>
                <p className="tm-color-gray">
                    {content1}
                </p>
                <p className="mb-0 tm-color-gray">
                    {content2}
                </p>
            </div>              
        </section>
  )
}
