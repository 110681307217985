import React from 'react'
import { Parallax } from 'react-parallax';
import img from '../assets/images/background.jpg'
import Introduce from '../sections/Introduce';


export default function ParallaxWindow() {

  const id = "intro";
  const title = "Introducing Asad Rahman";
  const content1 = "I am a web developer. I have 7+ years working experience in Web application development Using multiple programming languages.";
  const content2 = "I believe on quality code and always try to make my client happy. I do work till my client's satisfactions.";

  return (
    <Parallax
      className="parallax-window"
      data-parallax="scroll"
      bgImage={img}
      strength={100}
      >
      <div className="tm-section-wrap">
        <Introduce id={id} title={title} content1={content1} content2={content2} />
      </div>            
    </Parallax>
  )
}
