import React from 'react'

export default function Social() {
  return (
    <ul className="nav flex-row tm-social-links">
                <li className="nav-item">
                  <div className="nav-link tm-social-link">
                    <i className="fa fa-facebook-f"></i>
                    </div>
                </li>
                <li className="nav-item">
                <div className="nav-link tm-social-link">
                    <i className="fa fa-twitter"></i>
                 </div>
                </li>
                <li className="nav-item">
                <div className="nav-link tm-social-link">
                    <i className="fa fa-linkedin-in"></i>
                 </div>
                </li>
              </ul>
  )
}
