import React from 'react'
import Experience from './Experience'

export default function CompanyProfile() {
  return (
        <div className="col-xl-6">
            <div className="tm-section-half">
                <h2 className="tm-section-title tm-color-primary mb-4 tm_border_bottom">Company Profile</h2>
                <p><span className="tm_company_title">Web Designer and Developer | Soho Analytics</span> <br/><span>2015 - 2017</span> </p>
                <p><span className="tm_company_title">Web Designer and Developer | Systway</span> <br/><span>2018 - 2020</span> </p>
                <p><span className="tm_company_title">Operations Manager | Integritas Digital Australia </span> <br/><span>2021 - Continue</span> </p>
                <Experience />
            </div>
        </div>
  )
}
