import React from 'react'

export default function AboutMe() {
  return (
    <div className="col-xl-6">
        <div className="tm-section-half">                          
            <h2 className="tm-section-title tm-color-primary mb-4 tm_border_bottom">About Me</h2>
            <p className="mb-5">Meticulous web developer with over 7 years of front end experience and passion for responsive website design and a firm believer in the mobile-first approach. W3C certified. Implemented new responsive website approach which increased mobile traffic by 20%.
            </p>
            <p>After graduating from National University, I joined the design and developing team at Soho Analytics (2015 to 2017), Systway (2018 to 2020). And I joined Operations supervisor at Integritas Digital Australia (2021) the place I work on a extensive range of fascinating and significant projects.</p>
        </div>
    </div>
  )
}
