import React from 'react'

export default function Layout({children}) {
  return (
    <div className="container-fluid">
      <div className="row">
        {children}
      </div>
      </div>
  )
}
