import React from 'react'

export default function Experience() {
  return (
    <>
      <h2 className="tm-section-title tm-color-primary mb-4 tm_border_bottom">Experience</h2>
      <p>Here are a few technologies I've been working with recently:</p>
      <ul className="mm_list">
        <li>JavaScript</li>
        <li>HTML5 and CSS3</li>
        <li>React Js</li>
        <li>JQuery</li>
        <li>Php</li>
        <li>RESTful API</li>
        <li>Wordpress</li>
      </ul>
    </>
  )
}
