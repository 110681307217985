import React from 'react'
import Slider from "react-slick";
import workImg from '../assets/images/gallery/projects/1.png'
import gpxImg from '../assets/images/gallery/projects/2.png'
import reyImg from '../assets/images/gallery/projects/3.png'
import raffImg from '../assets/images/gallery/projects/4.png'
import carsImg from '../assets/images/gallery/projects/5.png'
import cosImg from '../assets/images/gallery/projects/6.png'
import ingImg from '../assets/images/gallery/projects/7.png'
import iprintImg from '../assets/images/gallery/projects/8.png'

export default function Work() {

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 5000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    dots: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                }
            }
        ]
    };

    return (
        <div className="tm-section-wrap bg-white">
            <section id="work" className="row tm-section">
                <div className="col-12">
                    <div className="w-100 tm-double-border-1 tm-border-blue">
                        <div className="tm-double-border-2 tm-border-blue tm-box-pad">
                            <div className="tm-gallery-wrap">
                                <h2 className="tm-color-primary tm-section-title mb-4 ml-2 tm_border_bottom">Works</h2>
                                <Slider {...settings} className="tm-gallery">
                                    <div className="tm-gallery-item">                        
                                    <figure className="effect-bubba">
                                        <img src={workImg} alt="" className="img-fluid" />
                                        <figcaption>
                                        <h2>Swanson Health<span>Asia Pacific</span></h2>
                                        </figcaption>
                                    </figure>
                                    </div>
                                    <div className="tm-gallery-item">
                                    <figure className="effect-bubba">
                                        <img src={gpxImg} alt="" className="img-fluid"/>
                                        <figcaption>
                                        <h2>GPX <span>Projects</span></h2>
                                        </figcaption>
                                    </figure>
                                    </div>
                                    <div className="tm-gallery-item">
                                    <figure className="effect-bubba">
                                        <img src={reyImg} alt="" className="img-fluid"/>
                                        <figcaption>
                                        <h2>Reynolds <span>Lending Solutions</span></h2>
                                        </figcaption>
                                    </figure>
                                    </div>
                                    <div className="tm-gallery-item">
                                    <figure className="effect-bubba">
                                        <img src={raffImg} alt="" className="img-fluid"/>
                                        <figcaption>
                                        <h2>Raffrey</h2>
                                        </figcaption>
                                    </figure>
                                    </div>
                                    <div className="tm-gallery-item">
                                    <figure className="effect-bubba">
                                        <img src={carsImg} alt="" className="img-fluid"/>
                                        <figcaption>
                                        <h2>Unwanted <span>Cars Sydney</span></h2>
                                        </figcaption>
                                    </figure>
                                    </div>
                                    <div className="tm-gallery-item">
                                    <figure className="effect-bubba">
                                        <img src={cosImg} alt="" className="img-fluid"/>
                                        <figcaption>
                                        <h2>Cosmelab</h2>
                                        </figcaption>
                                    </figure>
                                    </div>
                                    <div className="tm-gallery-item">
                                    <figure className="effect-bubba">
                                        <img src={ingImg} alt="" className="img-fluid"/>
                                        <figcaption>
                                        <h2>Ingleburn <span>Business Chamber</span></h2>
                                        </figcaption>
                                    </figure>
                                    </div>
                                    <div className="tm-gallery-item">
                                    <figure className="effect-bubba">
                                        <img src={iprintImg} alt="" className="img-fluid"/>
                                        <figcaption>
                                        <h2>iPrint <span>Digital</span></h2>
                                        </figcaption>
                                    </figure>
                                    </div>
                                </Slider>
                            </div>                        
                        </div>                  
                    </div>     
                </div>         
            </section>
        </div>
    )
}
