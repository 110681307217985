import React from 'react'
import ContactInfo from '../components/ContactInfo'
import ContactForm from '../components/ContactForm'

export default function Contact() {
  return (
    <div className="tm-section-wrap bg-white">
        <section id="talk" className="row tm-section">
            <div className="col-xl-6 mb-5">
                <div className="tm-double-border-1 tm-border-blue">
                    <ContactInfo/>
                </div>                  
            </div>
            <div className="col-xl-6 mb-5">
                <div className="tm-contact-form-wrap">
                    <ContactForm />
                </div>                    
            </div>
        </section>
    </div>
  )
}
