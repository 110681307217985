import React from 'react'
import gpxLogo from '../assets/images/clients/gpx_logo_.png'
import ibcLogo from '../assets/images/clients/ibc_logo.png'
import intLogo from '../assets/images/clients/integritas_logo.png'
import iprintLogo from '../assets/images/clients/iprint_logo.png'
import rentLogo from '../assets/images/clients/rentit_logo.png'
import hthLogo from '../assets/images/clients/hth_logo.png'
import reyLogo from '../assets/images/clients/rls_logo.png'
import stsLogo from '../assets/images/clients/sts_logo.png'

export default function Clients() {
  return (
    <div className="tm-section-wrap bg-white">
            <section id="clients" className="row tm-section">
              <div className="col-12 tm-section-pad">
                  <div className="tm-flex-item-left">
                      <div className="tm-w-100">
                          <h2 className="tm-color-primary tm-section-title mb-4 tm_border_bottom">Clients Review</h2>
                          <p className="mb-5">
                            Working with Asad Rahman has being easy over this short time “Our Site” has being operational. Asad Rahman is prompt, helpful and professional with their work ethics. Great to have around when you need to update your website.
                          </p>
                      </div>
                      
                      <div className="row tm-clients-images">
                        <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 tm-img-wrap">
                            <div className="client_logo">
                            <img src={gpxLogo} alt="" className="img-fluid tm-client-img" />
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 tm-img-wrap">
                        <div className="client_logo">
                            <img src={ibcLogo} alt="" className="img-fluid tm-client-img" />
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 tm-img-wrap">
                        <div className="client_logo">
                            <img src={intLogo} alt="" className="img-fluid tm-client-img" />
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 tm-img-wrap">
                        <div className="client_logo">
                            <img src={iprintLogo} alt="" className="img-fluid tm-client-img" />
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 tm-img-wrap">
                        <div className="client_logo">
                              <img src={rentLogo} alt="" className="img-fluid tm-client-img" />
                              </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 tm-img-wrap">
                          <div className="client_logo">
                              <img src={hthLogo} alt="" className="img-fluid tm-client-img" />
                              </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 tm-img-wrap">
                          <div className="client_logo">
                              <img src={reyLogo} alt="" className="img-fluid tm-client-img" />
                              </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 tm-img-wrap">
                          <div className="client_logo">
                              <img src={stsLogo} alt="" className="img-fluid tm-client-img" />
                              </div>
                          </div>
                      </div>
                  </div> 
              </div>                             
            </section>
          </div>
  )
}
