import React from 'react'
import AboutMe from '../components/AboutMe'
import CompanyProfile from '../components/CompanyProfile'

export default function About() {
  return (
    <div className="tm-section-wrap bg-white">
      <section id="about" className="row tm-section">
        <AboutMe />
        <CompanyProfile />
      </section>
    </div>
  )
}
