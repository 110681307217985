import React from 'react'

export default function ContactInfo() {
    return (
        <div className="tm-double-border-2 tm-border-blue tm-box-pad">
            <h2 className="tm-color-primary tm-section-title mb-4">Talk to Us</h2>
            <p className="mb-4">
                <strong>I would love to hear from you.</strong> Please contact me via telephone, or arrange a consultation from me by email.
            </p>
            <p className="mb-3">
                <strong>Email Address:</strong> asadkonok@gmail.com<br/>
                <strong>Phone Number:</strong> 01723 431614<br/>
                <strong>Skype:</strong> asad.rahman1988
            </p>
        </div>                    
    )
}
