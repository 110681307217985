import React, { useState } from 'react'
import {useRef} from 'react'
import emailjs from '@emailjs/browser';

export default function ContactForm() {

const form = useRef();

const [success, setSuccess] = useState(false);
const [error, setError] = useState(false);

const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_9mcmaip', 'template_r7er6ph', form.current, 'lq-Q8DzxYzt5W3NwV')
      .then((result) => {
            setSuccess('You are submitted successfully!');
            e.target.reset();
            setTimeout(() => {
                    setSuccess('');
              }, 5000);
      }, (error) => {
            setError('Something wrong!');
      });


      
};

    return (
        <>
        <form ref={form} onSubmit={sendEmail} action="" method="POST" className="tm-contact-form">
            <div className="form-group" id="name-group">
                <input type="text" id="contact_name" name="contact_name" className="form-control" placeholder="Your Name" required />
            </div>
            <div className="form-group" id="email-group">
                <input type="email" id="contact_email" name="contact_email" className="form-control" placeholder="Your Email" required />
            </div>
            <div className="form-group" id="message-group">
                <textarea rows="4" id="contact_message" name="contact_message" className="form-control" placeholder="Message..." required ></textarea>
            </div>
            <div className="form-group mb-0">
                <input type="submit" name="submit" className="submit btn d-block ml-auto tm-btn-primary" value="Submit" />
            </div>
            
            <div className="tm_notice">
            {success && <div className="alert alert-success">{success}</div>}
            {error && <div className="alert alert-error">{error}</div>}
            </div>
        </form>
        </>
    )
}
