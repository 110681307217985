import React from 'react'
// import { FontAwesomeIcon } from '@fortawesome/free-brands-svg-icons';
// import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'


export default function NavToggle() {
  return (
    <button className="navbar-toggler" type="button" aria-label="Toggle navigation">
             <i className="fa fa-bars"></i>
            </button>
  )
}
