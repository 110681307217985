import React from 'react'
import digitalImg from '../assets/images/chart-simple-solid.svg';
import websiteImg from '../assets/images/layer-group-solid.svg';
import developmentImg from '../assets/images/shop-solid.svg';
import hostingImg from '../assets/images/globe-solid.svg';

export default function Services() {
  return (
    <div className="tm-section-wrap bg-white">
            <section id="services" className="row tm-section">
              <div className="col-xl-12">
                <div className="tm-section-half">                           
                    <h2 className="tm-section-title tm-color-primary mb-5 tm_border_bottom">Services</h2>
                    <div className="row tm-service-items">
                        <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                            <div className="tm-double-border-1 tm-border-blue tm_pd">
                            <div style={{"textAlign":"center"}}><img src={digitalImg} className="mb-2 tm-sm-icon" alt="" /></div>
                            <p>Digital <br/>Marketing</p>
                            </div>
                            </div>
                        <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                            <div className="tm-double-border-1 tm-border-blue tm_pd">
                            <div style={{"textAlign":"center"}}><img src={websiteImg} className="mb-2 tm-sm-icon" alt="" /></div>
                           <p>Website <br/>Design</p>
                            </div>
                            </div>
                        <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                            <div className="tm-double-border-1 tm-border-blue tm_pd">
                            <div style={{"textAlign":"center"}}><img src={developmentImg} className="mb-2 tm-sm-icon" alt="" /></div>
                            <p>Website <br/>Development</p>
                            </div>
                            </div>
                        <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                            <div className="tm-double-border-1 tm-border-blue tm_pd">
                            <div style={{"textAlign":"center"}}><img src={hostingImg} className="mb-2 tm-sm-icon" alt="" /></div>
                            <p>Managed <br/>Hosting</p>
                            </div>
                            </div>
                    </div>
                </div>
              </div>
            </section>
          </div> 
  )
}
